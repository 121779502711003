@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'SeaweedScript Regular';
    src: url('./fonts/SeaweedScript-Regular.ttf');
}

@font-face {
    font-family: 'CedarvilleCursive Regular';
    src: url('./fonts/CedarvilleCursive-Regular.ttf');
}

@font-face {
    font-family: 'LaBelleAurore Regular';
    src: url('./fonts/LaBelleAurore-Regular.ttf');
}

@font-face {
    font-family: 'Poppins Regular';
    src: url('./fonts/Poppins-Regular.ttf')
}

.contract {
    font-family: "Poppins Regular";
    font-size: 14px;
}

.remove-input-txt-border input[type='text']:focus { box-shadow: none; }
.remove-input-txt-border-ant input[type='search']:focus { box-shadow: none; }

.plaintiffSignature {
    font-family: "CedarvilleCursive Regular";
    font-size: 24px;
}

.companyRepresentativeSignature {
    font-family: "SeaweedScript Regular";
    font-size: 24px;
}
.digital {
    color: #0493be;
    text-decoration: overline;
}
