.ag-theme-alpine {
    --ag-borders: solid 1px;
    --ag-border-color:  rgb(233, 233, 233);
}

.ag-root-wrapper {
    border-radius: 10px
}

.ag-theme-alpine .ag-header-cell {
    font-size: 14px;
}

.ag-theme-alpine .ag-cell-value {
    line-height: 20px !important;
    word-break: normal; /* prevent words from breaking */
    padding-top: 5px; /* space top */
    padding-bottom: 5px; /* space bottom */
}
