th, td {
    padding: 8px;
}

.main-table table {
    border-collapse: collapse;
}
.main-table tr {
    border: solid lightgray;
    border-width: 0.5px 0;
}
thead { display: table-header-group }
tfoot { display: table-row-group }
tr { page-break-inside: avoid }
